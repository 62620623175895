export default [
    "AccountBookFilled",
    "AccountBookOutlined",
    "AccountBookTwoTone",
    "AimOutlined",
    "AlertFilled",
    "AlertOutlined",
    "AlertTwoTone",
    "AlibabaOutlined",
    "AlignCenterOutlined",
    "AlignLeftOutlined",
    "AlignRightOutlined",
    "AlipayCircleFilled",
    "AlipayCircleOutlined",
    "AlipayOutlined",
    "AlipaySquareFilled",
    "AliwangwangFilled",
    "AliwangwangOutlined",
    "AliyunOutlined",
    "AmazonCircleFilled",
    "AmazonOutlined",
    "AmazonSquareFilled",
    "AndroidFilled",
    "AndroidOutlined",
    "AntCloudOutlined",
    "AntDesignOutlined",
    "ApartmentOutlined",
    "ApiFilled",
    "ApiOutlined",
    "ApiTwoTone",
    "AppleFilled",
    "AppleOutlined",
    "AppstoreAddOutlined",
    "AppstoreFilled",
    "AppstoreOutlined",
    "AppstoreTwoTone",
    "AreaChartOutlined",
    "ArrowDownOutlined",
    "ArrowLeftOutlined",
    "ArrowRightOutlined",
    "ArrowUpOutlined",
    "ArrowsAltOutlined",
    "AudioFilled",
    "AudioMutedOutlined",
    "AudioOutlined",
    "AudioTwoTone",
    "AuditOutlined",
    "BackwardFilled",
    "BackwardOutlined",
    "BankFilled",
    "BankOutlined",
    "BankTwoTone",
    "BarChartOutlined",
    "BarcodeOutlined",
    "BarsOutlined",
    "BehanceCircleFilled",
    "BehanceOutlined",
    "BehanceSquareFilled",
    "BehanceSquareOutlined",
    "BellFilled",
    "BellOutlined",
    "BellTwoTone",
    "BgColorsOutlined",
    "BlockOutlined",
    "BoldOutlined",
    "BookFilled",
    "BookOutlined",
    "BookTwoTone",
    "BorderBottomOutlined",
    "BorderHorizontalOutlined",
    "BorderInnerOutlined",
    "BorderLeftOutlined",
    "BorderOuterOutlined",
    "BorderOutlined",
    "BorderRightOutlined",
    "BorderTopOutlined",
    "BorderVerticleOutlined",
    "BorderlessTableOutlined",
    "BoxPlotFilled",
    "BoxPlotOutlined",
    "BoxPlotTwoTone",
    "BranchesOutlined",
    "BugFilled",
    "BugOutlined",
    "BugTwoTone",
    "BuildFilled",
    "BuildOutlined",
    "BuildTwoTone",
    "BulbFilled",
    "BulbOutlined",
    "BulbTwoTone",
    "CalculatorFilled",
    "CalculatorOutlined",
    "CalculatorTwoTone",
    "CalendarFilled",
    "CalendarOutlined",
    "CalendarTwoTone",
    "CameraFilled",
    "CameraOutlined",
    "CameraTwoTone",
    "CarFilled",
    "CarOutlined",
    "CarTwoTone",
    "CaretDownFilled",
    "CaretDownOutlined",
    "CaretLeftFilled",
    "CaretLeftOutlined",
    "CaretRightFilled",
    "CaretRightOutlined",
    "CaretUpFilled",
    "CaretUpOutlined",
    "CarryOutFilled",
    "CarryOutOutlined",
    "CarryOutTwoTone",
    "CheckCircleFilled",
    "CheckCircleOutlined",
    "CheckCircleTwoTone",
    "CheckOutlined",
    "CheckSquareFilled",
    "CheckSquareOutlined",
    "CheckSquareTwoTone",
    "ChromeFilled",
    "ChromeOutlined",
    "CiCircleFilled",
    "CiCircleOutlined",
    "CiCircleTwoTone",
    "CiOutlined",
    "CiTwoTone",
    "ClearOutlined",
    "ClockCircleFilled",
    "ClockCircleOutlined",
    "ClockCircleTwoTone",
    "CloseCircleFilled",
    "CloseCircleOutlined",
    "CloseCircleTwoTone",
    "CloseOutlined",
    "CloseSquareFilled",
    "CloseSquareOutlined",
    "CloseSquareTwoTone",
    "CloudDownloadOutlined",
    "CloudFilled",
    "CloudOutlined",
    "CloudServerOutlined",
    "CloudSyncOutlined",
    "CloudTwoTone",
    "CloudUploadOutlined",
    "ClusterOutlined",
    "CodeFilled",
    "CodeOutlined",
    "CodeSandboxCircleFilled",
    "CodeSandboxOutlined",
    "CodeSandboxSquareFilled",
    "CodeTwoTone",
    "CodepenCircleFilled",
    "CodepenCircleOutlined",
    "CodepenOutlined",
    "CodepenSquareFilled",
    "CoffeeOutlined",
    "ColumnHeightOutlined",
    "ColumnWidthOutlined",
    "CommentOutlined",
    "CompassFilled",
    "CompassOutlined",
    "CompassTwoTone",
    "CompressOutlined",
    "ConsoleSqlOutlined",
    "ContactsFilled",
    "ContactsOutlined",
    "ContactsTwoTone",
    "ContainerFilled",
    "ContainerOutlined",
    "ContainerTwoTone",
    "ControlFilled",
    "ControlOutlined",
    "ControlTwoTone",
    "CopyFilled",
    "CopyOutlined",
    "CopyTwoTone",
    "CopyrightCircleFilled",
    "CopyrightCircleOutlined",
    "CopyrightCircleTwoTone",
    "CopyrightOutlined",
    "CopyrightTwoTone",
    "CreditCardFilled",
    "CreditCardOutlined",
    "CreditCardTwoTone",
    "CrownFilled",
    "CrownOutlined",
    "CrownTwoTone",
    "CustomerServiceFilled",
    "CustomerServiceOutlined",
    "CustomerServiceTwoTone",
    "DashOutlined",
    "DashboardFilled",
    "DashboardOutlined",
    "DashboardTwoTone",
    "DatabaseFilled",
    "DatabaseOutlined",
    "DatabaseTwoTone",
    "DeleteColumnOutlined",
    "DeleteFilled",
    "DeleteOutlined",
    "DeleteRowOutlined",
    "DeleteTwoTone",
    "DeliveredProcedureOutlined",
    "DeploymentUnitOutlined",
    "DesktopOutlined",
    "DiffFilled",
    "DiffOutlined",
    "DiffTwoTone",
    "DingdingOutlined",
    "DingtalkCircleFilled",
    "DingtalkOutlined",
    "DingtalkSquareFilled",
    "DisconnectOutlined",
    "DislikeFilled",
    "DislikeOutlined",
    "DislikeTwoTone",
    "DollarCircleFilled",
    "DollarCircleOutlined",
    "DollarCircleTwoTone",
    "DollarOutlined",
    "DollarTwoTone",
    "DotChartOutlined",
    "DoubleLeftOutlined",
    "DoubleRightOutlined",
    "DownCircleFilled",
    "DownCircleOutlined",
    "DownCircleTwoTone",
    "DownOutlined",
    "DownSquareFilled",
    "DownSquareOutlined",
    "DownSquareTwoTone",
    "DownloadOutlined",
    "DragOutlined",
    "DribbbleCircleFilled",
    "DribbbleOutlined",
    "DribbbleSquareFilled",
    "DribbbleSquareOutlined",
    "DropboxCircleFilled",
    "DropboxOutlined",
    "DropboxSquareFilled",
    "EditFilled",
    "EditOutlined",
    "EditTwoTone",
    "EllipsisOutlined",
    "EnterOutlined",
    "EnvironmentFilled",
    "EnvironmentOutlined",
    "EnvironmentTwoTone",
    "EuroCircleFilled",
    "EuroCircleOutlined",
    "EuroCircleTwoTone",
    "EuroOutlined",
    "EuroTwoTone",
    "ExceptionOutlined",
    "ExclamationCircleFilled",
    "ExclamationCircleOutlined",
    "ExclamationCircleTwoTone",
    "ExclamationOutlined",
    "ExpandAltOutlined",
    "ExpandOutlined",
    "ExperimentFilled",
    "ExperimentOutlined",
    "ExperimentTwoTone",
    "ExportOutlined",
    "EyeFilled",
    "EyeInvisibleFilled",
    "EyeInvisibleOutlined",
    "EyeInvisibleTwoTone",
    "EyeOutlined",
    "EyeTwoTone",
    "FacebookFilled",
    "FacebookOutlined",
    "FallOutlined",
    "FastBackwardFilled",
    "FastBackwardOutlined",
    "FastForwardFilled",
    "FastForwardOutlined",
    "FieldBinaryOutlined",
    "FieldNumberOutlined",
    "FieldStringOutlined",
    "FieldTimeOutlined",
    "FileAddFilled",
    "FileAddOutlined",
    "FileAddTwoTone",
    "FileDoneOutlined",
    "FileExcelFilled",
    "FileExcelOutlined",
    "FileExcelTwoTone",
    "FileExclamationFilled",
    "FileExclamationOutlined",
    "FileExclamationTwoTone",
    "FileFilled",
    "FileGifOutlined",
    "FileImageFilled",
    "FileImageOutlined",
    "FileImageTwoTone",
    "FileJpgOutlined",
    "FileMarkdownFilled",
    "FileMarkdownOutlined",
    "FileMarkdownTwoTone",
    "FileOutlined",
    "FilePdfFilled",
    "FilePdfOutlined",
    "FilePdfTwoTone",
    "FilePptFilled",
    "FilePptOutlined",
    "FilePptTwoTone",
    "FileProtectOutlined",
    "FileSearchOutlined",
    "FileSyncOutlined",
    "FileTextFilled",
    "FileTextOutlined",
    "FileTextTwoTone",
    "FileTwoTone",
    "FileUnknownFilled",
    "FileUnknownOutlined",
    "FileUnknownTwoTone",
    "FileWordFilled",
    "FileWordOutlined",
    "FileWordTwoTone",
    "FileZipFilled",
    "FileZipOutlined",
    "FileZipTwoTone",
    "FilterFilled",
    "FilterOutlined",
    "FilterTwoTone",
    "FireFilled",
    "FireOutlined",
    "FireTwoTone",
    "FlagFilled",
    "FlagOutlined",
    "FlagTwoTone",
    "FolderAddFilled",
    "FolderAddOutlined",
    "FolderAddTwoTone",
    "FolderFilled",
    "FolderOpenFilled",
    "FolderOpenOutlined",
    "FolderOpenTwoTone",
    "FolderOutlined",
    "FolderTwoTone",
    "FolderViewOutlined",
    "FontColorsOutlined",
    "FontSizeOutlined",
    "ForkOutlined",
    "FormOutlined",
    "FormatPainterFilled",
    "FormatPainterOutlined",
    "ForwardFilled",
    "ForwardOutlined",
    "FrownFilled",
    "FrownOutlined",
    "FrownTwoTone",
    "FullscreenExitOutlined",
    "FullscreenOutlined",
    "FunctionOutlined",
    "FundFilled",
    "FundOutlined",
    "FundProjectionScreenOutlined",
    "FundTwoTone",
    "FundViewOutlined",
    "FunnelPlotFilled",
    "FunnelPlotOutlined",
    "FunnelPlotTwoTone",
    "GatewayOutlined",
    "GifOutlined",
    "GiftFilled",
    "GiftOutlined",
    "GiftTwoTone",
    "GithubFilled",
    "GithubOutlined",
    "GitlabFilled",
    "GitlabOutlined",
    "GlobalOutlined",
    "GoldFilled",
    "GoldOutlined",
    "GoldTwoTone",
    "GoldenFilled",
    "GoogleCircleFilled",
    "GoogleOutlined",
    "GooglePlusCircleFilled",
    "GooglePlusOutlined",
    "GooglePlusSquareFilled",
    "GoogleSquareFilled",
    "GroupOutlined",
    "HddFilled",
    "HddOutlined",
    "HddTwoTone",
    "HeartFilled",
    "HeartOutlined",
    "HeartTwoTone",
    "HeatMapOutlined",
    "HighlightFilled",
    "HighlightOutlined",
    "HighlightTwoTone",
    "HistoryOutlined",
    "HomeFilled",
    "HomeOutlined",
    "HomeTwoTone",
    "HourglassFilled",
    "HourglassOutlined",
    "HourglassTwoTone",
    "Html5Filled",
    "Html5Outlined",
    "Html5TwoTone",
    "IdcardFilled",
    "IdcardOutlined",
    "IdcardTwoTone",
    "IeCircleFilled",
    "IeOutlined",
    "IeSquareFilled",
    "ImportOutlined",
    "InboxOutlined",
    "InfoCircleFilled",
    "InfoCircleOutlined",
    "InfoCircleTwoTone",
    "InfoOutlined",
    "InsertRowAboveOutlined",
    "InsertRowBelowOutlined",
    "InsertRowLeftOutlined",
    "InsertRowRightOutlined",
    "InstagramFilled",
    "InstagramOutlined",
    "InsuranceFilled",
    "InsuranceOutlined",
    "InsuranceTwoTone",
    "InteractionFilled",
    "InteractionOutlined",
    "InteractionTwoTone",
    "IssuesCloseOutlined",
    "ItalicOutlined",
    "KeyOutlined",
    "LaptopOutlined",
    "LayoutFilled",
    "LayoutOutlined",
    "LayoutTwoTone",
    "LeftCircleFilled",
    "LeftCircleOutlined",
    "LeftCircleTwoTone",
    "LeftOutlined",
    "LeftSquareFilled",
    "LeftSquareOutlined",
    "LeftSquareTwoTone",
    "LikeFilled",
    "LikeOutlined",
    "LikeTwoTone",
    "LineChartOutlined",
    "LineHeightOutlined",
    "LineOutlined",
    "LinkOutlined",
    "LinkedinFilled",
    "LinkedinOutlined",
    "Loading3QuartersOutlined",
    "LoadingOutlined",
    "LockFilled",
    "LockOutlined",
    "LockTwoTone",
    "LoginOutlined",
    "LogoutOutlined",
    "MacCommandFilled",
    "MacCommandOutlined",
    "MailFilled",
    "MailOutlined",
    "MailTwoTone",
    "ManOutlined",
    "MedicineBoxFilled",
    "MedicineBoxOutlined",
    "MedicineBoxTwoTone",
    "MediumCircleFilled",
    "MediumOutlined",
    "MediumSquareFilled",
    "MediumWorkmarkOutlined",
    "MehFilled",
    "MehOutlined",
    "MehTwoTone",
    "MenuFoldOutlined",
    "MenuOutlined",
    "MenuUnfoldOutlined",
    "MergeCellsOutlined",
    "MessageFilled",
    "MessageOutlined",
    "MessageTwoTone",
    "MinusCircleFilled",
    "MinusCircleOutlined",
    "MinusCircleTwoTone",
    "MinusOutlined",
    "MinusSquareFilled",
    "MinusSquareOutlined",
    "MinusSquareTwoTone",
    "MobileFilled",
    "MobileOutlined",
    "MobileTwoTone",
    "MoneyCollectFilled",
    "MoneyCollectOutlined",
    "MoneyCollectTwoTone",
    "MonitorOutlined",
    "MoreOutlined",
    "NodeCollapseOutlined",
    "NodeExpandOutlined",
    "NodeIndexOutlined",
    "NotificationFilled",
    "NotificationOutlined",
    "NotificationTwoTone",
    "NumberOutlined",
    "OneToOneOutlined",
    "OrderedListOutlined",
    "PaperClipOutlined",
    "PartitionOutlined",
    "PauseCircleFilled",
    "PauseCircleOutlined",
    "PauseCircleTwoTone",
    "PauseOutlined",
    "PayCircleFilled",
    "PayCircleOutlined",
    "PercentageOutlined",
    "PhoneFilled",
    "PhoneOutlined",
    "PhoneTwoTone",
    "PicCenterOutlined",
    "PicLeftOutlined",
    "PicRightOutlined",
    "PictureFilled",
    "PictureOutlined",
    "PictureTwoTone",
    "PieChartFilled",
    "PieChartOutlined",
    "PieChartTwoTone",
    "PlayCircleFilled",
    "PlayCircleOutlined",
    "PlayCircleTwoTone",
    "PlaySquareFilled",
    "PlaySquareOutlined",
    "PlaySquareTwoTone",
    "PlusCircleFilled",
    "PlusCircleOutlined",
    "PlusCircleTwoTone",
    "PlusOutlined",
    "PlusSquareFilled",
    "PlusSquareOutlined",
    "PlusSquareTwoTone",
    "PoundCircleFilled",
    "PoundCircleOutlined",
    "PoundCircleTwoTone",
    "PoundOutlined",
    "PoweroffOutlined",
    "PrinterFilled",
    "PrinterOutlined",
    "PrinterTwoTone",
    "ProfileFilled",
    "ProfileOutlined",
    "ProfileTwoTone",
    "ProjectFilled",
    "ProjectOutlined",
    "ProjectTwoTone",
    "PropertySafetyFilled",
    "PropertySafetyOutlined",
    "PropertySafetyTwoTone",
    "PullRequestOutlined",
    "PushpinFilled",
    "PushpinOutlined",
    "PushpinTwoTone",
    "QqCircleFilled",
    "QqOutlined",
    "QqSquareFilled",
    "QrcodeOutlined",
    "QuestionCircleFilled",
    "QuestionCircleOutlined",
    "QuestionCircleTwoTone",
    "QuestionOutlined",
    "RadarChartOutlined",
    "RadiusBottomleftOutlined",
    "RadiusBottomrightOutlined",
    "RadiusSettingOutlined",
    "RadiusUpleftOutlined",
    "RadiusUprightOutlined",
    "ReadFilled",
    "ReadOutlined",
    "ReconciliationFilled",
    "ReconciliationOutlined",
    "ReconciliationTwoTone",
    "RedEnvelopeFilled",
    "RedEnvelopeOutlined",
    "RedEnvelopeTwoTone",
    "RedditCircleFilled",
    "RedditOutlined",
    "RedditSquareFilled",
    "RedoOutlined",
    "ReloadOutlined",
    "RestFilled",
    "RestOutlined",
    "RestTwoTone",
    "RetweetOutlined",
    "RightCircleFilled",
    "RightCircleOutlined",
    "RightCircleTwoTone",
    "RightOutlined",
    "RightSquareFilled",
    "RightSquareOutlined",
    "RightSquareTwoTone",
    "RiseOutlined",
    "RobotFilled",
    "RobotOutlined",
    "RocketFilled",
    "RocketOutlined",
    "RocketTwoTone",
    "RollbackOutlined",
    "RotateLeftOutlined",
    "RotateRightOutlined",
    "SafetyCertificateFilled",
    "SafetyCertificateOutlined",
    "SafetyCertificateTwoTone",
    "SafetyOutlined",
    "SaveFilled",
    "SaveOutlined",
    "SaveTwoTone",
    "ScanOutlined",
    "ScheduleFilled",
    "ScheduleOutlined",
    "ScheduleTwoTone",
    "ScissorOutlined",
    "SearchOutlined",
    "SecurityScanFilled",
    "SecurityScanOutlined",
    "SecurityScanTwoTone",
    "SelectOutlined",
    "SendOutlined",
    "SettingFilled",
    "SettingOutlined",
    "SettingTwoTone",
    "ShakeOutlined",
    "ShareAltOutlined",
    "ShopFilled",
    "ShopOutlined",
    "ShopTwoTone",
    "ShoppingCartOutlined",
    "ShoppingFilled",
    "ShoppingOutlined",
    "ShoppingTwoTone",
    "ShrinkOutlined",
    "SignalFilled",
    "SisternodeOutlined",
    "SketchCircleFilled",
    "SketchOutlined",
    "SketchSquareFilled",
    "SkinFilled",
    "SkinOutlined",
    "SkinTwoTone",
    "SkypeFilled",
    "SkypeOutlined",
    "SlackCircleFilled",
    "SlackOutlined",
    "SlackSquareFilled",
    "SlackSquareOutlined",
    "SlidersFilled",
    "SlidersOutlined",
    "SlidersTwoTone",
    "SmallDashOutlined",
    "SmileFilled",
    "SmileOutlined",
    "SmileTwoTone",
    "SnippetsFilled",
    "SnippetsOutlined",
    "SnippetsTwoTone",
    "SolutionOutlined",
    "SortAscendingOutlined",
    "SortDescendingOutlined",
    "SoundFilled",
    "SoundOutlined",
    "SoundTwoTone",
    "SplitCellsOutlined",
    "StarFilled",
    "StarOutlined",
    "StarTwoTone",
    "StepBackwardFilled",
    "StepBackwardOutlined",
    "StepForwardFilled",
    "StepForwardOutlined",
    "StockOutlined",
    "StopFilled",
    "StopOutlined",
    "StopTwoTone",
    "StrikethroughOutlined",
    "SubnodeOutlined",
    "SwapLeftOutlined",
    "SwapOutlined",
    "SwapRightOutlined",
    "SwitcherFilled",
    "SwitcherOutlined",
    "SwitcherTwoTone",
    "SyncOutlined",
    "TableOutlined",
    "TabletFilled",
    "TabletOutlined",
    "TabletTwoTone",
    "TagFilled",
    "TagOutlined",
    "TagTwoTone",
    "TagsFilled",
    "TagsOutlined",
    "TagsTwoTone",
    "TaobaoCircleFilled",
    "TaobaoCircleOutlined",
    "TaobaoOutlined",
    "TaobaoSquareFilled",
    "TeamOutlined",
    "ThunderboltFilled",
    "ThunderboltOutlined",
    "ThunderboltTwoTone",
    "ToTopOutlined",
    "ToolFilled",
    "ToolOutlined",
    "ToolTwoTone",
    "TrademarkCircleFilled",
    "TrademarkCircleOutlined",
    "TrademarkCircleTwoTone",
    "TrademarkOutlined",
    "TransactionOutlined",
    "TranslationOutlined",
    "TrophyFilled",
    "TrophyOutlined",
    "TrophyTwoTone",
    "TwitterCircleFilled",
    "TwitterOutlined",
    "TwitterSquareFilled",
    "UnderlineOutlined",
    "UndoOutlined",
    "UngroupOutlined",
    "UnlockFilled",
    "UnlockOutlined",
    "UnlockTwoTone",
    "UnorderedListOutlined",
    "UpCircleFilled",
    "UpCircleOutlined",
    "UpCircleTwoTone",
    "UpOutlined",
    "UpSquareFilled",
    "UpSquareOutlined",
    "UpSquareTwoTone",
    "UploadOutlined",
    "UsbFilled",
    "UsbOutlined",
    "UsbTwoTone",
    "UserAddOutlined",
    "UserDeleteOutlined",
    "UserOutlined",
    "UserSwitchOutlined",
    "UsergroupAddOutlined",
    "UsergroupDeleteOutlined",
    "VerifiedOutlined",
    "VerticalAlignBottomOutlined",
    "VerticalAlignMiddleOutlined",
    "VerticalAlignTopOutlined",
    "VerticalLeftOutlined",
    "VerticalRightOutlined",
    "VideoCameraAddOutlined",
    "VideoCameraFilled",
    "VideoCameraOutlined",
    "VideoCameraTwoTone",
    "WalletFilled",
    "WalletOutlined",
    "WalletTwoTone",
    "WarningFilled",
    "WarningOutlined",
    "WarningTwoTone",
    "WechatFilled",
    "WechatOutlined",
    "WeiboCircleFilled",
    "WeiboCircleOutlined",
    "WeiboOutlined",
    "WeiboSquareFilled",
    "WeiboSquareOutlined",
    "WhatsAppOutlined",
    "WifiOutlined",
    "WindowsFilled",
    "WindowsOutlined",
    "WomanOutlined",
    "YahooFilled",
    "YahooOutlined",
    "YoutubeFilled",
    "YoutubeOutlined",
    "YuqueFilled",
    "YuqueOutlined",
    "ZhihuCircleFilled",
    "ZhihuOutlined",
    "ZhihuSquareFilled",
    "ZoomInOutlined",
    "ZoomOutOutlined"
  ]