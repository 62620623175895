<template>
  <div class="page-layout">
      <slot></slot>
  </div>
</template>
<script>
import "./index.less";
export default {
  name:"page-layout"
}
</script>